/* global chrome */
import React, { useState } from 'react';
import { AppBar, Avatar, Toolbar, IconButton, Typography, InputBase, Button, Badge, MenuItem, Menu, CircularProgress, Icon, Grid, Popover, List, Paper, ListItem } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import LoginIcon from '@mui/icons-material/Login';
//import HomeIcon from '@mui/icons-material/Home';
//import ForumIcon from '@mui/icons-material/Forum';

import crownImg from './crown.png'

import MailIcon from '@mui/icons-material/Mail';
import LanguageIcon from '@mui/icons-material/Language';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MoreIcon from '@mui/icons-material/MoreVert';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import SearchBar from './searchBar';

import Extension from '../extension';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'


const SearchAppBar = (props) => {

	const dispatch = useDispatch();
  const navigate = useNavigate();
	const user = useSelector(state => state.user);
	const { root, extensionInstalled, extensionId } = useSelector(state => state.api);

  const [anchorEl, setAnchorEl] = React.useState(null);
	const [searchAnchorEl, setSearchAnchorEl] = React.useState(null);
	const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);
  const [notificationLoading, setNotificationLoading] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [autoCompleteList, setAutoCompleteList] = React.useState([]);
  const [logOutLoading, setLogOutLoading] = React.useState(false);
  const [ notifications, setNotifications ] = React.useState([]);
  const [isHovered, setIsHovered] = useState(false);
	
  const isMenuOpen = Boolean(anchorEl);
  const isNotificationOpen = Boolean(notificationAnchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


  function handleOpenUrl(event) {
    window.open('https://chrome.google.com/webstore/detail/trends/malnhaodjdegcljjebmpopbjmckblndn','hi').focus()
  }

  function handleSignOut(event) {
    navigate('/en');
		setLogOutLoading(true)
		fetch(root + `/auth/signout`, { credentials: 'include' })
			.then(res => {
				return res.json();
			})
			.then(json => {
				setLogOutLoading(false)
				console.log('json is', json)
				dispatch({type: 'DESTROY_USER_$'});
			})
			.catch(err => console.log(err))
  }

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleNotificationOpen(event) {
    setNotificationLoading(true);
    setNotificationAnchorEl(event.currentTarget);
		fetch(root + `/auth/notification/open`, { credentials: 'include' })
			.then(res => {
				return res.json();
			})
			.then(json => {
        console.log('json is', json)
        dispatch({type: 'OPEN_NOTIFICATION'})
        setNotificationLoading(false)
        setNotifications(json.notifications);
			})
			.catch(err => console.log(err))
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
    setNotificationAnchorEl(null);
    handleMobileMenuClose();
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const handleNotificationContentClick = notification =>{
    setAnchorEl(null)
    setMobileMoreAnchorEl(null)
    setNotificationAnchorEl(null)
    console.log('extensionInstalled', extensionInstalled)
    if(extensionInstalled){
      chrome.runtime.sendMessage(extensionId, {type: 'OPEN_WEBPAGE'}, function(response) {
        console.log('sent message to extension')
        window.open(notification.content[0].urls[0].href, 'hi').focus()
      });
    } else {
      dispatch({type: 'OPEN_EXTENSION_REQUEST_$'})
    }
  }

  const validURL = string => {
    const  url = 'https://' + string;
    console.log('url is', url);
    try { return Boolean(new URL(url)); }
    catch(e){ console.log('not valid');return false; }
  }

  const menuId = 'primary-search-account-menu';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
			<MenuItem onClick={()=>navigate('/privacy')}>
        privacy
      </MenuItem>
			<MenuItem onClick={handleSignOut}>
        sign out
      </MenuItem>
    </Menu>
  );

  const handleNotificationClick = notification => {
    chrome.runtime.sendMessage({type: "OPEN_WEBPAGE"}, function(response) {
      console.log('response is', response);
    });
    var u = new URL(notification.content[0].urls[0].href);
    u.searchParams.append('platfor_content_objectId', notification.content[0]._id);
    var win = window.open(u.toString(), '_blank');
    win.focus();
  }

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          size="large">
          <MenuIcon />
        </IconButton>
      </MenuItem>
    </Menu>
  );

  const appBarOpacity = !user? 0 : isHovered? 1 : 0.6;

  return (
    <>
      <AppBar 
        position='fixed'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          //visibility: isHovered ? 'visible' : 'hidden',
          opacity: appBarOpacity,
          transition: 'opacity 0.3s ease',
        }}
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        >
        <Toolbar>
          <IconButton size='large' onClick={()=>{
              navigate('/extension/welcome')
            }} 
          >
            <QuestionMarkIcon />
          </IconButton>

          <IconButton size='large' onClick={()=>{
              navigate('/en')
            }} 
          >
            <HomeIcon />
          </IconButton>
          <div>
						<SearchBar />
          </div>
          <div/>
            {
              user&&user._id
              ?
                <div>
                  <IconButton size='large' onClick={()=>{
                      if(!user) return dispatch({ type: 'OPEN_SIGN_$' });
                      navigate('/newsfeed')
                    }} 
                  >
                    <Badge color='secondary' variant={user.newsfeedCount>0? 'dot' : 'standard'}>
                      <PeopleAltIcon />
                    </Badge>
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-haspopup="true"
                    onClick={e=>{
                      /*
                        e.preventDefault();
                          chrome.runtime.sendMessage(extensionId, {type: 'OPEN_ACTIVITY_BY_WEBPAGE', personName: user.username, user }, function(response) {
                        })
                      */
                      if(user.completed){
                        navigate(`/${user.username}`)
                      } else {
                        navigate(`/settings`) 
                      }
                    }}
                    size="large"
                    socialbrowse-activity-button
                  >
                    <Avatar className='special-button' src={user.profileImageUrl}/>
                  </IconButton>
                </div>
              :
                <div>
                  <IconButton size='large' onClick={()=>{
                    navigate('/extension/signin')
                    }} 
                  >
                      <LoginIcon />
                  </IconButton>
                  {//<Skeleton style={{cursor: 'pointer'}} onClick={() => props.navigate('/extension/signin')} variant="circular"><Avatar /></Skeleton>
                  }
                </div>
            }
          {
            /*

          <div className={classes.sectionDesktop}>
            <IconButtoi
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpe}
              size="large">
              <MoreIcon />
            </IconButton>
          </div>
            */
          }
          {/* {
            user?.subscriptionStatus == 'DONOR'
            ?
              <IconButton size='large' onClick={()=>{
                  if(!user) return dispatch({ type: 'OPEN_SIGN_$' });
                  if(user&&user.subscriptionStatus !== 'DONOR') return navigate('/pricing')
                  navigate('/pricing');
                }} 
              >
                <img src={crownImg} style={{width: '25px'}}/>
              </IconButton>
            :
              <IconButton size='large' onClick={()=>{
                  //if(!user) return dispatch({ type: 'OPEN_SIGN_$' });
                  navigate('/pricing');
                }} 
              >
                <BeenhereIcon/>
              </IconButton>
          } */}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </>
  );
}

export default SearchAppBar;
