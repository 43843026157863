/* global chrome */

import React, { Fragment, Component } from 'react'
import Privacy from './components/privacy';
import Donation from './components/donation';
import Extension from './components/extension';
import AppBar from './components/appBar';

import './bootstrap.css';
import './style.css';

import Home from 'components/home';
import Search from 'components/search';
import Newsfeed from 'components/newsfeed';
import Activity from 'components/activity';
//import Suggest from 'components/suggest';
import Settings from 'components/settings';
//import Dialogs from 'components/dialogs';

import Canvas from 'components/canvas';
import Premium from 'components/premium';
import Pricing from 'components/pricing';

import SignIn from 'components/home/signIn';
import SignInScreen from 'components/signInScreen';
import Uncompleted from 'components/settings/uncompleted';
import Welcome from 'components/welcome';
import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';

import { Skeleton } from '@mui/material';

import { useNavigate, useLocation } from "react-router-dom";
import { Provider, useSelector, useDispatch } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import { createLogger } from 'redux-logger';
import rootSaga from './sagas';

import demoUserJson from 'components/newsfeed/demoUser.json';

import socket from 'socket';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

const sagaMiddleware = createSagaMiddleware();
const logger = createLogger();


let middleware = [];

if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, sagaMiddleware, logger];
} else {
  middleware = [...middleware, sagaMiddleware, logger];
}
const store = createStore(
	rootReducer,
  applyMiddleware(...middleware),
)

sagaMiddleware.run(rootSaga);


const Loader = props => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const { extensionId, extensionInstalled, userLoaded } = useSelector(state => state.api);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    dispatch({ type: 'SET_USER', method: 'get', path:'/auth/user' + '?nocache=' + new Date().getTime() })
    //dispatch({type: 'SET_DEMO_USER', demoUserJson })
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if(isChrome){
      dispatch({type: 'IS_CHROME'})
      console.log('sending the version request to extension')

      if (!chrome.runtime){
        dispatch({type: "EXTENSION_NOT_INSTALLED"});
        console.log('Extension is not installed');
      } else {
        dispatch({type: "EXTENSION_INSTALLED"})
        console.log('Extension is installed');
      }
      
      // chrome.runtime.sendMessage(extensionId, {type: 'VERSION'},
      //   function(response){
      //     console.log('response is', response)
      //     if (!response.success){
      //       dispatch({type: "EXTENSION_NOT_INSTALLED"});
      //       console.log('Extension is not installed');
      //     } else {
      //       dispatcxh({type: "EXTENSION_INSTALLED"})
      //       console.log('Extension is installed');
      //     }
      // });
    }
  },
  [])
 

  React.useEffect(() =>{
    const path = location.pathname;
    console.log('path is ', path)
    if(path == '/en') return;
    if(path == '/privacy') return;
    if (path.substring(1).includes('/')) {
      console.log('it starts with /extension')
      return;
    }

    if(path == '/extension/welcome') return;
    if(path == '/newsfeed') return;

    if(!userLoaded) return navigate('/en');

    /*
    const pathRegex = /^\/([^\/]+)\/([^\/]+)/;
    const match = path.match(pathRegex);
    if (match) {
      const [_, userName, tagName] = match;
      // Now you have access to userName and tagName, you can use them as needed
      console.log('User Name:', userName);
      console.log('Tag Name:', tagName);
      // You can navigate or perform other actions based on these values
      return;
    }
    */

    if(userLoaded&&!user) return navigate('/en');
    if(user&&!user.completed){
      navigate('/extension/uncompleted');
      return;
    }

    //if(path === '/kr' || path ==='/newsfeed' || path === '/en') return;
    if(user&&user._id){
      navigate(`/${user.username}`); 
      return;
    }
  }, [user, userLoaded])

  return(
    <div align="center">
      {
        userLoaded
      ?
          null
        :
          null
      }
    </div>
  )
}


const SignedIn = props => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const { extensionId, extensionInstalled, userLoaded } = useSelector(state => state.api);

  const navigate = useNavigate();

  React.useEffect(() => {
  
    if(!userLoaded) return;
    if(user&&!user.completed){
      navigate('/extension/uncompleted');
      return;
    }
    if(user&&user.completed){
      navigate(`/${user.username}`); 
      return;
    }

  }, [user, userLoaded])

  return(
    <div align="center">
    </div>
  )
}

const theme = createTheme(adaptV4Theme({
  palette: {
    white: '#FFFFFF',
    text:{
      primary: '#000000',
      secondary: '#676160',
      contrast: '#FFFFFF',
    }
  },
  breakpoints: {
    values: {

      xs: 0,
      sm: 600,
      md: 900,
      lg: 1400,
      xl: 1700,

      ms_1: 0,
      ms_2: 600,
      ms_3: 1200,
      ms_4: 1800,
      ms_5: 2400,
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      // '"Segoe UI"',
      // 'Roboto',
      // '"Helvetica Neue"',
      // 'Arial',
      'sans-serif',
      // '"Apple Color Emoji"',
      // '"Segoe UI Emoji"',
      // '"Segoe UI Symbol"',
    ].join(','),
  },
}));

const App = props => {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <Router>
              <Loader/>
              <AppBar/>
              {/*
              <Dialogs/>

                <Route path='/suggest' element={Suggest}/>
              */
              }
              <Routes>
                <Route exact path='/premium' element={<Premium/>}/>
                <Route exact path='/pricing' element={<Pricing/>}/>
                <Route exact path='/extension/welcome' element={<Welcome/>}/>
                <Route exact path='/extension/signed_in' element={<SignedIn/>}/>
                <Route exact path='/extension/signin' element={<SignInScreen/>}/>
                <Route exact path='/extension/uncompleted' element={<Uncompleted/>}/>
                <Route exact path='/:person_name/:canvas_name' element={<Canvas/>}/>
                <Route exact path='/settings' element={<Settings/>}/>
                <Route exact path='/privacy' element={<Privacy/>}/>
                <Route exact path='/search' element={<Search/>}/>
                <Route exact path='/newsfeed' element={<Newsfeed/>}/>
                <Route exact path='/en' element={<Home/>}/>
                <Route exact path='/:person_name' element={<Activity/>}/>
                <Route path={'/'} render={() =>
                  <Fragment>
                    <Home />
                  </Fragment>
                }
                />
              </Routes>
            </Router>
          </Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
}

export default App;
